// Traffic types
export const WEB_APP = 'WebApp';
export const DASHBOARD = 'Dashboard';

// Chat support events
export const HOST_END_CALL_CHAT_SUPPORT_EVENT = 'HOST_END_CALL_CHAT_SUPPORT_EVENT';

// Marketing constants
export const USER_LANDED_SIGNUP_PAGE = 'USER_LANDED_SIGNUP_PAGE';
export const USER_LANDED_LOGIN_PAGE = 'USER_LANDED_LOGIN_PAGE';

// Event tracking
export const LOCK = 'ROOM_LOCKED';
export const UNLOCK = 'ROOM_UNLOCKED';
export const SCREEN_SHARE = 'SHARESCREEN_ENABLED';
export const CHAT = 'IN_CALL_CHAT';

// Attendee type
export const USER = 'User';
export const HOST = 'Host';
export const GUEST = 'Guest';
export const INTERNAL = 'Internal';
export const EXTERNAL = 'External';

// video call meeting related
export const FEEDBACK_GIVEN = 'FEEDBACK_GIVEN';
export const INVITE_TO_CALL = 'INVITE_TO_CALL';
export const AUDIO_MUTED = 'AUDIO_MUTED';
export const VIDEO_TURNED_OFF = 'VIDEO_TURNED_OFF';
export const VIDEO_TURNED_ON = 'VIDEO_TURNED_ON';
export const CALL_RECORDED = 'CALL_RECORDED';
export const JOIN_VIDEO_CALL = 'JOIN_VIDEO_CALL';
export const JOIN_TELECAST_CALL = 'JOIN_TELECAST_CALL';
export const DROP_DOWN_LOG_OUT_SELECTED = 'DROP_DOWN_LOG_OUT_SELECTED';
export const DROP_DOWN_DASHBOARD_SELECTED = 'DROP_DOWN_DASHBOARD_SELECTED';
export const HOST_NOTIFIED = 'HOST_NOTIFIED';
export const PUSH_NOTIFICATIONS_ENABLED = 'PUSH_NOTIFICATIONS_ENABLED';
export const PUSH_NOTIFICATIONS_DISMISSED = 'PUSH_NOTIFICATIONS_DISMISSED';
export const TOGGLE_SPEAKER_VIEW = 'TOGGLE_SPEAKER_VIEW';
export const ACCESS_SETTINGS = 'ACCESS_SETTINGS';

// Account config related aka Dashboard
export const CLAIM_ROOM_CHANGE = 'CLAIM_ROOM_CHANGE';
export const EDIT_COMPANY_NAME = 'EDIT_COMPANY_NAME';
export const PRE_CALL_IMG_CHANGE = 'PRE_CALL_IMG_CHANGE';
export const ADD_COMPANY_NAME = 'ADD_COMPANY_NAME';
export const IN_CALL_IMG_CHANGE = 'IN_CALL_IMG_CHANGE';
export const WAITING_MESSAGE_CHANGE = 'WAITING_MESSAGE_CHANGE';
export const ADD_CLAIM_ROOM = 'ADD_CLAIM_ROOM';
export const EDIT_PROFILE_IMG = 'EDIT_PROFILE_IMG';
export const EDIT_ROLE = 'EDIT_ROLE';
export const EDIT_FULL_NAME = 'EDIT_FULL_NAME';
export const EDIT_PHONE = 'EDIT_PHONE';
export const START_VIDEO_CALL = 'START_VIDEO_CALL';
export const START_TELECAST_CALL = 'START_TELECAST_CALL';
export const LOGO_IMG_CHANGE = 'LOGO_IMG_CHANGE';

// Feature events

export const VIDEO_MESSAGE_SCREEN_ONLY = 'VIDEO_MESSAGE_SCREEN_ONLY';
export const VIDEO_MESSAGE_WITH_VIDEO = 'VIDEO_MESSAGE_WITH_VIDEO';
