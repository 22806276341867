/* eslint-disable */
import { makePostRequest } from '../es6/fetchRequest';


const MAX_FIELD_LENGTH_FOR_SIGNUP = 60;
export default class EmailHelper {
  emailNode = document.getElementById('email');

  passNode = document.getElementById('password');

  nameNode = document.getElementById('name');

  passNumErrNode = document.getElementById('number');

  passLetterErrNode = document.getElementById('letter');

  passSplCharErrNode = document.getElementById('splChar');

  passLengthErrNode = document.getElementById('length');

  passSpaceErrNode = document.getElementById('space');

  emailErrorNode = document.getElementById('email-err');

  invalidFieldNode = document.querySelector('.error-feedback')

  constructor () {
    this.resetErrFields = this.resetErrFields.bind(this);
    if (this.emailNode) {
      this.emailNode.addEventListener('keyup', this.resetErrFields);
    }
    if (this.passNode) {
      this.passNode.addEventListener('keyup', this.resetErrFields);
    }
  }
  
  resetErrFields () {
    if (this.invalidFieldNode) {
      this.invalidFieldNode.classList.add('hide');
    }
  }

  isEmailValid = (email) => {
    const re = /^\w+([\.\-\+]\w+)*\w*@(\w+\.)+\w+$/gm;
    return email.length < MAX_FIELD_LENGTH_FOR_SIGNUP && (re.test(email));
  }

  doesPasswordContainNumber = password => /[0-9]/.test(password);

  doesPasswordContainSpaceError = password => !/^\s|\s$/.test(password); // starting and ending shouldn't have space

  doesPasswordContainEnoughLength = password => /^.{6,64}$/g.test(password);

  doesPasswordContainSpecialChar = password => /[@$!%*#?&]/.test(password);

  doesPasswordContainsLetter = password => /[A-Za-z]/.test(password);

  isPasswordValid = password => (this.doesPasswordContainEnoughLength(password)
    && this.doesPasswordContainNumber(password)
    && this.doesPasswordContainSpaceError(password)
    && this.doesPasswordContainSpecialChar(password)
    && this.doesPasswordContainsLetter(password))

  showEmailErrorMsg = () => {
    const email = this.emailNode.value;
    this.emailErrorNode.innerText = 'Invalid Email';
    if (this.isEmailValid(email)) {
      this.emailNode.className = 'valid';
    } else {
      this.emailNode.className = 'error';
    }
  }

  showNameErrorMSg = () => {
    const { value } = this.nameNode;
    const isNameValid = /^[a-zA-Z0-9_. ]*$/.test(value) && value.length < MAX_FIELD_LENGTH_FOR_SIGNUP;
    if (isNameValid) {
      this.nameNode.className = 'valid';
    } else {
      this.nameNode.className = 'error';
    }
  }

  showPassErrorMsg = () => {
    const password = this.passNode.value;
    if (this.doesPasswordContainEnoughLength(password)) {
      this.passLengthErrNode.className = 'success';
    } else {
      this.passLengthErrNode.className = 'failed';
    }

    if (this.doesPasswordContainNumber(password)) {
      this.passNumErrNode.className = 'success';
    } else {
      this.passNumErrNode.className = 'failed';
    }

    if (this.doesPasswordContainSpaceError(password)) {
      this.passSpaceErrNode.className = 'success';
    } else {
      this.passSpaceErrNode.className = 'failed';
    }

    if (this.doesPasswordContainSpecialChar(password)) {
      this.passSplCharErrNode.className = 'success';
    } else {
      this.passSplCharErrNode.className = 'failed';
    }

    if (this.doesPasswordContainsLetter(password)) {
      this.passLetterErrNode.className = 'success';
    } else {
      this.passLetterErrNode.className = 'failed';
    }

    if (this.doesPasswordContainSpaceError(password)) {
      this.passSpaceErrNode.className = 'success';
    } else {
      this.passSpaceErrNode.className = 'failed';
    }

    if (this.isPasswordValid(password)) {
      this.passNode.className = 'valid';
    } else {
      this.passNode.className = 'error';
    }
  }

  getName() {
    return this.nameNode.value;
  }

  getPassword() {
    return this.passNode.value;
  }

  getEmail() {
    return this.emailNode.value;
  }

  getContinueUrl = () => {
    const searchParams = new URLSearchParams(location.href);
    return searchParams.get('continue_url') || '';
  }

  isFreeTrial = () => {
    const params = new URLSearchParams(location.search);
    return params.get('isFreeTrial');
  }

  getTierPlan = () => {
    const params = (new URL(document.location)).searchParams;
    let freePlanId = 'ab6fab05'
    return params.get('tier') || freePlanId;
  }


  getInviteId() {
    const url = window.location.pathname.split('/');
    if (url && url.length >= 2) {
      return url[2];
    }
    return '';
  }

  emailSignupCb = async () => {
    const name = this.getName();
    const email = this.getEmail();
    const password = this.getPassword();
    document.getElementById('sign-up-with-email').classList.add('disabled');
    if (this.isEmailValid(email) && this.isPasswordValid(password) && name) {
      const continue_url = this.getContinueUrl();
      const tier = this.getTierPlan();
      const free_trial = this.isFreeTrial();
      let is_free_trial = 'false'
      if (free_trial && free_trial === 'true') {
        is_free_trial = 'true'
      }
      const inviteId = this.getInviteId();
      try {
        const result = await makePostRequest({
          data: {
            name,
            email,
            password,
            continue_url,
            tier,
            is_free_trial,
            inviteId
          },
          endpoint: '/signup',
          options: { credentials: 'same-origin' },
        });
        if (result.status === 'success') {
          console.log('signup success');
          location.href = result.redirect_url;
        } else {
          console.warn('signing up failed becasue of', result);
          const emailErrorMessage = inviteId ? result.msg : 'This email address is already used by another account.';
          this.emailErrorNode.innerText = emailErrorMessage;
          this.emailNode.className = 'error';
        }
      } catch (e) {
        console.error('signing up failed with an error ', e);
      }
    } else {
      this.showNameErrorMSg();
      this.showEmailErrorMsg();
      this.showPassErrorMsg();
    }
    document.getElementById('sign-up-with-email').classList.remove('disabled');
  }

  emailLoginCB = async (continue_url) => {
    const username = this.getEmail();
    const password = this.getPassword();
    const params = { username, password, redirect: true };
    document.getElementById('sign-up-with-email').classList.add('disabled');
    window.webAuth.loginWithCredentials(params, (ok, response) => {
      console.log('full auth response', ok);
      if (!ok) {
        this.invalidFieldNode.classList.remove('hide');
      }
      document.getElementById('sign-up-with-email').classList.remove('disabled');
    });
  }
}
