import '../../assets/scss/login.scss';
import EmailHelper from '../../js/utils/emailHelper';
import { USER_LANDED_SIGNUP_PAGE } from '../../js/es6/analytics/analyticsConstants';

const emailHelper = new EmailHelper();
window.awConfig = {};

if (process.env.NODE_ENV === 'production') {
  window.awConfig.APP_URL = 'https://teleport.video';
  window.awConfig.customDomain = 'https://auth.teleport.video'
} else if (process.env.NODE_ENV === 'staging') {
  window.awConfig.APP_URL = 'https://staging.teleport.video';
  window.awConfig.customDomain = 'https://auth.staging.teleport.video'
} else {
  window.awConfig.APP_URL = 'http://localhost:8888';
  window.awConfig.customDomain = 'https://auth.staging.teleport.video'
}

function changeTitle() {
  const searchParams = new URLSearchParams(location.href);
  const state = searchParams.get('state');
  if (state) {
    const title = document.getElementById('title');
    if (title) {
      title.innerHTML = 'Log in to <br> start the meeting';
    }
  }
}

const getContinueURL = (fromLocation) => {
  if (fromLocation) {
    const searchParams = new URLSearchParams(location.href);
    const state = searchParams.get('state');
    return state ? state.split('is_continue=')[1] : '/';
  }
  try {
  const continueUrl = localStorage.getItem('continue_url');
  const continueTime = localStorage.getItem('continue_time') || new Date();
  const isNotExpired = (new Date() - new Date(continueTime)) / (1000 * 60 * 60) < 2;
  return isNotExpired ? continueUrl : '/';
  } catch (error) {
    console.error('Error:localStorage.getItem() of continue_url');
    console.error(error);
  }
};

function showEmailPanel() {
  const options = document.getElementsByClassName('options-wrapper')[0];
  if (options) {
    options.className = `${options.classList[0]} hide`;
  }
  const email = document.getElementsByClassName('email-login-inputs')[0];
  if (email) {
    email.className = `${email.classList[0]}`;
  }
}


function showSignUpOptions() {
  const options = document.getElementsByClassName('options-wrapper')[0];
  if (options) {
    options.className = `${options.classList[0]}`;
  }
  const email = document.getElementsByClassName('email-login-inputs')[0];
  if (email) {
    email.className = `${email.classList[0]} hide`;
  }
}

export function getLocationUrl(event) {
  if (event && event.data && event.data.from === 'connect-script' && event.data.type === 'authDomain') {
    const { authDomain, userVersionUrl } = event.data;
    console.log('authDomain is ', authDomain);
    return`${authDomain}/o/sso/CheckCookie?continue=${userVersionUrl}&domains=${window.awConfig.customDomain}`;
  } return "";
}

 function addEventListener() {
  window.addEventListener('message', (event) => {
    if (event.data.from === 'connect-script' && event.data.type === 'authDomain') {
      const locationUrl = getLocationUrl(event)
      window.location = locationUrl;
    }
  });

  const googleLogin = document.getElementById('google_login');
  if (googleLogin) {
    googleLogin.onclick = () => {
      window.webAuth.socialAuth('google');
    };
  }

  const fbLogin = document.getElementById('fb_login');
  if (fbLogin) {
    fbLogin.onclick = () => {
      window.location = window.webAuth.socialLoginUrl('facebook');
    };
  }

  const signup = document.getElementById('signup');
  if (signup) {
    signup.onclick = () => {
      console.log(window.awConfig.APP_URL);
      location.href = `${window.awConfig.APP_URL}/w/pricing`;
    };
  }

  const changeOptions = document.getElementsByClassName('email-signin')[0];
  if (changeOptions) {
    changeOptions.addEventListener('click', showEmailPanel);
  }

  const signInWithEmail = document.getElementById('sign-up-with-email');
  if (signInWithEmail) {
    signInWithEmail.onclick = () => emailHelper.emailLoginCB(getContinueURL(true));
  }

   const passwordEl = document.getElementById('password');
   if (passwordEl) {
     passwordEl.addEventListener('keypress', ({ keyCode }) => {
       const isEnter = keyCode === 13;
       if (isEnter) {
         emailHelper.emailLoginCB(getContinueURL(true));
       }
     })
   }

  const backBtn = document.getElementById('back-btn');
  if (backBtn) {
    backBtn.onclick = showSignUpOptions;
  }
}

const confirmCreateAcc = (method, email) => {
  const logo = document.getElementById('logo');
  const consentPanel = document.getElementById('consent-panel');
  const loginOpt = document.getElementById('login-opt');
  loginOpt.classList.add('hide');
  consentPanel.classList.remove('hide');
  switch (method) {
    case 'google':
      logo.classList.remove('facebook');
      logo.classList.add('google');
      break;
    case 'facebook':
      logo.classList.add('facebook');
      logo.classList.remove('google');
      break;
    default:
      console.log('unreaable code');
  }
  const emailTried = document.getElementById('email-tried');
  if (emailTried) {
    emailTried.innerText = email;
  }
  const createNew = document.getElementById('create-new');
  const changeAcc = document.getElementById('change-diff-acc');
  return new Promise((res, rej) => {
    createNew.onclick = res;
    changeAcc.onclick = rej;
  });
};

const switchAcc = () => {
  const consentPanel = document.getElementById('consent-panel');
  const loginOpt = document.getElementById('login-opt');
  loginOpt.classList.remove('hide');
  consentPanel.classList.add('hide');
};

const handleAccountNotCreated = async () => {
  const isUserTriedFB = location.pathname.includes('/social/facebook');
  const isUserTriedGoogle = location.pathname.includes('/social/google');
  // const continueUrl = getContinueURL(false);
  const { login_hint : loginHint = '' } = window.config.error || {};
  if (isUserTriedFB) {
    confirmCreateAcc('facebook', loginHint).then(() => {
      location.href = `${window.awConfig.APP_URL}/w/pricing`;
    }).catch(switchAcc);
  } else if (isUserTriedGoogle) {
    confirmCreateAcc('google', loginHint).then(() => {
      location.href = `${window.awConfig.APP_URL}/w/pricing`;
    }).catch(switchAcc);
  }
};

const setContinueURLInLocalStorage = () => {
  const continueUrl = getContinueURL(true);
  try  {
  if (continueUrl) {
    localStorage.setItem('continue_url', continueUrl);
    localStorage.setItem('continue_time', new Date());
  }
 }  catch (error) {
  console.error('Error: localStorage.setItem() of continue_url');
  console.error(error);
}
};

function showSignupButton () {
  const searchParams = new URLSearchParams(window.location.href);
  const isDirectCall = searchParams.get('isDirectCall');
  window.directcall = isDirectCall;
  console.log(isDirectCall);
  if(isDirectCall) {
    const signUpButton = document.getElementsByClassName('sign-in-options');
    if(signUpButton) signUpButton[0].style.visibility = 'hidden';
  }
}

/**
 * Set USER_LANDED_SIGNUP_PAGE in LS
 * will be used in marketingAnalytics.js in /user/account# called from billingInfo.js
 */
function removeUserloginFromLocalStorage() {
  try {
    localStorage.removeItem(USER_LANDED_SIGNUP_PAGE);
    console.log('MarketingAnalytics: USER_LANDED_SIGNUP_PAGE analytics REMOVING from LS');
  } catch (error) {
    console.error('Error: localStorage.removeItem');
    console.error(error);
  }
}

window.loadCb = () => {
  removeUserloginFromLocalStorage();
  addEventListener();
  changeTitle();
  window.parent.postMessage({ type: 'login' }, '*');
  handleAccountNotCreated();
  setContinueURLInLocalStorage();
  showSignupButton();
};
