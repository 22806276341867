import { ENV } from "../utils/sharedConstants";

const config = {
  development: {
    mode: 'development',
    RTMServerKey: '2eb1809d-9e52-d9bd-d4b7-22fd2be0e12c',
    RTMMode: 'staging',
    RTM_URL: 'wss://stagingrtm.anywhereworks.com/connection?apiKey=2eb1809d-9e52-d9bd-d4b7-22fd2be0e12c',
    EXTENSION_ID: 'ekmeddlbpnkmbfbhinjpafgfeibmhjnl',
    APP_URL: 'https://staging.teleport.video',
    APP_DOMAIN: 'staging.teleport.video',
    FIREBASE_DYNAMIC_LINK_DOMAIN: 'https://stagingteleport.page.link',
    ANDROID_APP_ID: 'com.anywhere.link',
    IOS_BUNDLE_ID: 'io.full.awlink',
    IOS_APP_STORE_ID: 1331488862,
    firebaseConfig: {
      apiKey: 'AIzaSyBrKWtGD4N5nY_BAV_3yIsCL32eMmalutk',
      authDomain: 'staging-connectaw.firebaseapp.com',
      databaseURL: 'https://staging-connectaw.firebaseio.com',
      projectId: 'staging-connectaw',
      storageBucket: 'staging-connectaw.appspot.com',
      messagingSenderId: '416661452442',
      appId: '1:416661452442:web:e5df5cfa0f21b05df69123',
      measurementId: 'G-W4ELVS2XWY'
    },
    FIREBASE_WEB_API_KEY: 'AIzaSyBneC5KJ7TC3fAAkDoUCsaNCjYhzVIIPi4',
    PAYMENT_AUTH_KEY: 'sandbox_jywyd5c6_6xmcgkh9ghgsj8q2',
    AW_BASE_URL: 'https://api-dot-staging-fullspectrum.appspot.com',
    LOGGING_SERVER_URL: 'https://nodejs-dot-staging-connectaw.appspot.com'
  },
  staging: {
    mode: 'staging',
    RTMServerKey: '2eb1809d-9e52-d9bd-d4b7-22fd2be0e12c',
    RTMMode: 'staging',
    RTM_URL: 'wss://stagingrtm.anywhereworks.com/connection?apiKey=2eb1809d-9e52-d9bd-d4b7-22fd2be0e12c',
    EXTENSION_ID: 'eijmngcldeebcljbcmmpjoffggcengci',
    APP_URL: 'https://staging.teleport.video',
    APP_DOMAIN: 'staging.teleport.video',
    FIREBASE_DYNAMIC_LINK_DOMAIN: 'https://stagingteleport.page.link',
    ANDROID_APP_ID: 'com.anywhere.link',
    IOS_BUNDLE_ID: 'io.full.awlink',
    IOS_APP_STORE_ID: 1331488862,
    firebaseConfig: {
      apiKey: 'AIzaSyBrKWtGD4N5nY_BAV_3yIsCL32eMmalutk',
      authDomain: 'staging-connectaw.firebaseapp.com',
      databaseURL: 'https://staging-connectaw.firebaseio.com',
      projectId: 'staging-connectaw',
      storageBucket: 'staging-connectaw.appspot.com',
      messagingSenderId: '416661452442',
      appId: '1:416661452442:web:e5df5cfa0f21b05df69123',
      measurementId: 'G-W4ELVS2XWY'
    },
    FIREBASE_WEB_API_KEY: 'AIzaSyBneC5KJ7TC3fAAkDoUCsaNCjYhzVIIPi4',
    PAYMENT_AUTH_KEY: 'sandbox_jywyd5c6_6xmcgkh9ghgsj8q2',
    AW_BASE_URL: 'https://api-dot-staging-fullspectrum.appspot.com',
    LOGGING_SERVER_URL: 'https://nodejs-dot-staging-connectaw.appspot.com'
  },
  production: {
    mode: 'production',
    RTMServerKey: 'faf22208-b90b-c8e9-b23b-16e49615eecb',
    RTMMode: 'live',
    RTM_URL: 'wss://rtmserver.anywhereworks.com/connection?apiKey=faf22208-b90b-c8e9-b23b-16e49615eecb',
    EXTENSION_ID: 'ppnedjgfgifikffabnchhklloiiioklm',
    APP_URL: 'https://teleport.video',
    APP_DOMAIN: 'teleport.video',
    FIREBASE_DYNAMIC_LINK_DOMAIN: 'https://teleport.page.link',
    ANDROID_APP_ID: 'com.anywhere.link',
    IOS_BUNDLE_ID: 'io.full.awlink',
    IOS_APP_STORE_ID: 1331488862,
    firebaseConfig: {
      apiKey: 'AIzaSyBneC5KJ7TC3fAAkDoUCsaNCjYhzVIIPi4',
      authDomain: 'live-meetme.firebaseapp.com',
      databaseURL: 'https://live-meetme.firebaseio.com',
      projectId: 'live-meetme',
      storageBucket: 'live-meetme.appspot.com',
      messagingSenderId: '706850759418',
      appId: '1:706850759418:web:b1916c717a2ec4f5',
    },
    FIREBASE_WEB_API_KEY: 'AIzaSyBneC5KJ7TC3fAAkDoUCsaNCjYhzVIIPi4',
    PAYMENT_AUTH_KEY: 'production_xxcjs68r_qjnq5gd5v763vs49',
    AW_BASE_URL: 'https://api.anywhereworks.com',
    LOGGING_SERVER_URL: 'https://nodejs-dot-live-meetme.appspot.com'
  },
};

function isNode() {
  return (typeof process !== 'undefined') && (process.release) && (process.release.name === 'node');
}

function getEnv (host = location.host) {
  let env = ENV.DEVELOPMENT;
  if (/(staging.teleport.video|staging-connectaw)/.test(host)) {
    env = ENV.STAGING;
  } else if (/(teleport.video|live-meetme)/.test(host)) {
    env = ENV.PRODUCTION;
  }
  return env;
}

function getConfig (host = location.host) {
  const env = getEnv(host);
  return config[env];
}

function getFireBaseConfig() {
  const configInfo = getConfig();
  return configInfo.firebaseConfig;
}

export { getEnv, getConfig, isNode, getFireBaseConfig };
